import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { documentTableViewerSelector } from '../../viewer-components/viewer-selectors';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CleanUp } from '../../../shared/cleanup';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { ExportableChartComponentWithControls } from '../../../features/graphs/exportable-chart';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  observeOn,
  ReplaySubject,
  startWith,
  switchMap,
} from 'rxjs';
import { ViewerResultData } from '../../viewer-components/viewer-document-data';
import {
  selectCurrentSelectionForNgsDocument,
  selectDataForNgsDocument,
  selectGraphLoadingStateForNgsDocument,
} from './ngs-graph-data-store/ngs-graph-data-store.selectors';
import {
  NgsGraph,
  GraphTypes,
  SelectionDisplayType,
  SelectionDisplayOptions,
  GraphId,
} from './ngs-graphs.model';
import {
  getTableForGraph,
  getTableSelection,
  GraphSelectionData,
  isIgnorableParameterForGraph,
} from './ngs-graph-data-store/ngs-graph-data-store.reducer';
import { ngsGraphActions } from './ngs-graph-data-store/ngs-graph-data-store.actions';
import { AnnotatedPluginDocument } from '../../geneious';
import { GraphSidebarControl } from '../../../features/graphs/graph-sidebar';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { selectionsAreEqual } from './ngs-graph-data-store/ngs-graph-data-store.effects';
import { NgsGraphCompatibilityService } from './ngs-graph-compatibility.service';
import { DocumentServiceResource } from '../../../../nucleus/services/documentService/document-service.resource';
import { isAllSequencesTable, isClusterTable } from '../table-type-filters';
import { sanitizeDTSTableOrColumnName } from '../../../../nucleus/services/documentService/document-service.v1';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { GraphZoomComponent, ZoomableChart } from './graph-zoom/graph-zoom.component';
import { NgsZoomService } from './graph-zoom/ngs-zoom.service';
import { NgClass, AsyncPipe } from '@angular/common';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { NgsSankeyPlotComponent } from './ngs-overview-graphs/ngs-sankey-plot/ngs-sankey-plot.component';
import { NgsAnnotationRatesGraphComponent } from './ngs-overview-graphs/ngs-annotation-rates-graph/ngs-annotation-rates-graph.component';
import { NgsClusterDiversityGraphComponent } from './ngs-cluster-graphs/ngs-cluster-diversity-graph/ngs-cluster-diversity-graph.component';
import { NgsClusterLengthsGraphComponent } from './ngs-cluster-graphs/ngs-cluster-lengths-graph/ngs-cluster-lengths-graph.component';
import { NgsClusterSizesGraphComponent } from './ngs-cluster-graphs/ngs-cluster-sizes-graph/ngs-cluster-sizes-graph.component';
import { NgsClusterNumbersGraphComponent } from './ngs-overview-graphs/ngs-cluster-numbers-graph/ngs-cluster-numbers-graph.component';
import { NgsAminoAcidDistributionChartComponent } from './ngs-cluster-graphs/ngs-amino-acid-distribution-chart/ngs-amino-acid-distribution-chart.component';
import { NgsCodonDistributionChartComponent } from './ngs-cluster-graphs/ngs-codon-distribution-chart/ngs-codon-distribution-chart.component';
import { NgsNumberOfGenesGraphComponent } from './ngs-overview-graphs/ngs-number-of-genes-graph/ngs-number-of-genes-graph.component';
import { NgsGeneCombinationsGraphComponent } from './ngs-overview-graphs/ngs-gene-combinations-graph/ngs-gene-combinations-graph.component';
import { NgsGeneFamilyUsageGraphComponent } from './ngs-overview-graphs/ngs-gene-family-usage-graph/ngs-gene-family-usage-graph.component';
import { NgsClusterSummaryGraphComponent } from './ngs-cluster-graphs/ngs-cluster-summary-graph/ngs-cluster-summary-graph.component';
import { NgsClusterNetworkComponent } from './ngs-cluster-graphs/ngs-cluster-network/ngs-cluster-network.component';
import { GraphSidebarComponent } from '../../../features/graphs/graph-sidebar/graph-sidebar.component';
import { FormsModule } from '@angular/forms';
import { GraphSidebarOptionsService } from '../../user-settings/graph-sidebar-options/graph-sidebar-options.service';

@ViewerComponent({
  key: 'ngs-graphs-viewer',
  title: 'Graphs',
  featureSwitch: 'ngsGraphsTab',
  selector: documentTableViewerSelector([
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.SEQUENCES,
    },
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.CLUSTERS,
    },
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.CLUSTER_GENE,
    },
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.INEXACT_CLUSTER,
    },
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.ANNOTATOR_RESULT_CHAIN_COMBINATIONS,
    },
  ]),
})
@Component({
  selector: 'bx-ngs-graphs',
  templateUrl: './ngs-graphs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ToolstripComponent,
    ToolstripItemComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    FaIconComponent,
    PageMessageComponent,
    LoadingComponent,
    NgClass,
    NgsSankeyPlotComponent,
    NgsAnnotationRatesGraphComponent,
    NgsClusterDiversityGraphComponent,
    NgsClusterLengthsGraphComponent,
    NgsClusterSizesGraphComponent,
    NgsClusterNumbersGraphComponent,
    NgsAminoAcidDistributionChartComponent,
    NgsCodonDistributionChartComponent,
    NgsNumberOfGenesGraphComponent,
    NgsGeneCombinationsGraphComponent,
    NgsGeneFamilyUsageGraphComponent,
    NgsClusterSummaryGraphComponent,
    NgsClusterNetworkComponent,
    GraphSidebarComponent,
    FormsModule,
    AsyncPipe,
    GraphZoomComponent,
  ],
})
export class NgsGraphsComponent extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass =
    'flex-grow-1 flex-shrink-1 d-flex flex-column overflow-hidden';
  @ViewChild('exportable') chartComponent: ExportableChartComponentWithControls<any>;

  selectableGraphs$: Observable<NgsGraph[]>;
  loading$: Observable<boolean>;
  message$ = this.completeOnDestroy(new ReplaySubject<string>());
  selectedGraph$: BehaviorSubject<NgsGraph>;
  anyGraphsAvailable$: Observable<boolean>;
  viewerData$: Observable<ViewerResultData>;
  documentID$: Observable<string>;
  currentGraphParams$: Observable<GraphSelectionData & { id: string }>;
  document$: Observable<AnnotatedPluginDocument>;
  controls$ = new BehaviorSubject<GraphSidebarControl[]>([]);
  tablesForDocument$: Observable<Record<string, DocumentTable>>;
  clusterTableNames$: Observable<string[]>;
  controlsAreNotEmpty$: Observable<boolean>;
  pngExportEnabled$ = this.completeOnDestroy(new BehaviorSubject(true));
  tableExportEnabled$ = this.completeOnDestroy(new BehaviorSubject(true));
  selectionDisplayType$: BehaviorSubject<SelectionDisplayType>;
  graphWarning$ = this.completeOnDestroy(new BehaviorSubject<string | null>(null));
  shouldDisplaySelectionDisplayType$: Observable<boolean>;
  selectableGraphsLoading$: Observable<boolean>;
  hideControlsExceptGraphSelector$: Observable<boolean>;
  graphIdForSidebar$: Observable<GraphSidebarId | null>;
  zoomControls$: Observable<ZoomableChart | null>;
  showGraph$ = this.completeOnDestroy(new BehaviorSubject<boolean>(true));
  protected readonly SelectionDisplayOptions = SelectionDisplayOptions;

  constructor(
    private viewerDataService: ViewerDataService,
    private store: Store<AppState>,
    private documentTableStateService: DocumentTableStateService,
    private graphCompatibilityService: NgsGraphCompatibilityService,
    private documentServiceResource: DocumentServiceResource,
    private ngsZoomService: NgsZoomService,
    private graphSidebarOptionsService: GraphSidebarOptionsService,
  ) {
    super();
    this.zoomControls$ = this.ngsZoomService
      .getZoomControls()
      .pipe(observeOn(asyncScheduler), takeUntil(this.ngUnsubscribe));
    const cachedGraph = this.graphSidebarOptionsService.getLastCachedGraph();
    this.selectedGraph$ = this.completeOnDestroy(new BehaviorSubject<NgsGraph>(cachedGraph));
    this.selectionDisplayType$ = this.completeOnDestroy(
      new BehaviorSubject<SelectionDisplayType>(
        this.graphSidebarOptionsService.getLastSelectionDisplayType(),
      ),
    );
  }

  ngOnInit(): void {
    this.ngsZoomService.deregisterZoomControls();
    this.selectableGraphsLoading$ =
      this.graphCompatibilityService.isLoading$.pipe(distinctUntilChanged());
    this.controlsAreNotEmpty$ = this.controls$.pipe(map((x) => !!x && x.length > 0));
    this.viewerData$ = this.viewerDataService.getData('ngs-graphs-viewer');
    this.documentID$ = this.viewerData$.pipe(map(({ selectedTable }) => selectedTable.documentID));
    this.tablesForDocument$ = this.documentID$.pipe(
      switchMap((doc) => this.getTablesForDocument(doc)),
    );
    this.clusterTableNames$ = this.tablesForDocument$.pipe(
      map((tables) => {
        const allSeqs = Object.values(tables).find(isAllSequencesTable);
        return Object.keys(tables)
          .filter(
            (table) =>
              isClusterTable(tables[table]) &&
              allSeqs.columns.some(
                (column) =>
                  column.name === `${sanitizeDTSTableOrColumnName(tables[table].displayName)} ID`,
              ),
          )
          .map((table) => tables[table].displayName);
      }),
    );
    const selectedTable$ = this.viewerData$.pipe(
      map((data) => data.selectedTable),
      distinctUntilChanged((oldTable, newTable) => oldTable.name === newTable.name),
    );
    const selection$ = this.viewerData$.pipe(map((data) => data.selection));
    const filter$ = this.viewerData$.pipe(
      map(({ filter }) => filter),
      distinctUntilChanged(),
    );
    this.currentGraphParams$ = this.documentID$.pipe(
      switchMap((id) => this.store.pipe(select(selectCurrentSelectionForNgsDocument(id)))),
      distinctUntilChanged(
        (oldSelection, newSelection) =>
          oldSelection?.updatedAt?.value === newSelection?.updatedAt?.value,
      ),
      takeUntil(this.ngUnsubscribe),
    );
    this.currentGraphParams$
      .pipe(
        filter((params) => params?.selectedGraph?.value !== GraphTypes.None.id),
        take(1),
      )
      .subscribe(({ selectedGraph }) => {
        const graph = Object.values(GraphTypes).find(
          (graphType) => graphType.id === selectedGraph?.value,
        );
        if (graph) {
          this.selectedGraph$.next(graph);
        }
      });

    filter$
      .pipe(withLatestFrom(this.documentID$, this.selectedGraph$), takeUntil(this.ngUnsubscribe))
      .subscribe(([unparsedFilter, id, graph]) => {
        const cols = this.documentServiceResource.getCurrentColumns() ?? [];
        const filter = DocumentServiceResource.parseFilterModel(unparsedFilter, cols);
        this.store.dispatch(
          ngsGraphActions.params.filter.update({
            id,
            graphId: graph.id,
            value: {
              filter,
            },
          }),
        );
      });

    this.document$ = selection$.pipe(
      map(({ document }) => document),
      takeUntil(this.ngUnsubscribe),
    );
    this.document$
      .pipe(
        distinctUntilChanged((oldDoc, newDoc) => oldDoc.id === newDoc.id),
        withLatestFrom(this.documentID$, this.selectedGraph$),
      )
      .subscribe(([doc, id, graph]) => {
        this.store.dispatch(
          ngsGraphActions.params.documentName.update({
            id,
            graphId: graph.id,
            value: {
              documentName: doc.name,
            },
          }),
        );
      });

    selectedTable$
      .pipe(
        map(getTableForGraph),
        withLatestFrom(this.documentID$, this.selectedGraph$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([selectedTable, id, graph]) => {
        this.store.dispatch(
          ngsGraphActions.params.selectedTable.update({
            id,
            graphId: graph.id,
            value: {
              selectedTable,
            },
          }),
        );
      });

    selection$
      .pipe(
        withLatestFrom(selectedTable$),
        map(([selection, table]) => getTableSelection(selection, table)),
        distinctUntilChanged((oldSelection, newSelection) =>
          selectionsAreEqual(oldSelection, newSelection),
        ),
        withLatestFrom(this.documentID$, this.selectedGraph$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([tableSelection, id, graph]) => {
        this.store.dispatch(
          ngsGraphActions.params.tableSelection.update({
            id,
            graphId: graph.id,
            value: {
              tableSelection,
            },
          }),
        );
      });

    this.loading$ = this.documentID$.pipe(
      switchMap((id) => this.store.select(selectGraphLoadingStateForNgsDocument(id))),
      takeUntil(this.ngUnsubscribe),
    );

    /*
     * What I'm trying to do here is delay the transition from "no error message" to "error message"
     * without delaying the reverse transition. Not entirely sure if it's a good idea to do it like this.
     * */
    const noErrorMessage$ = combineLatest([
      combineLatest([this.documentID$, this.selectedGraph$]).pipe(
        switchMap(([documentID, selectedGraph]) =>
          this.store.pipe(
            selectDataForNgsDocument<typeof selectedGraph.id>(documentID, selectedGraph.id),
            takeUntil(this.ngUnsubscribe),
          ),
        ),
        map((data) => !!data),
        takeUntil(this.ngUnsubscribe),
      ),
      this.loading$,
      this.selectedGraph$,
    ]).pipe(
      map(([dataIsNonNull, loading, graph]) => dataIsNonNull || loading || graph.id === 'noGraph'),
      takeUntil(this.ngUnsubscribe),
    );
    merge(
      noErrorMessage$.pipe(
        filter((x) => !x),
        delay(5000),
        takeUntil(noErrorMessage$),
      ),
      noErrorMessage$.pipe(filter((x) => !!x)),
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((noErrorMessage) => {
        if (!noErrorMessage) {
          this.message$.next('Something went wrong: cannot load graph');
        } else {
          this.message$.next(null);
        }
      });
    this.selectableGraphs$ = this.getSelectableGraphs();
    combineLatest([this.selectableGraphs$, selectedTable$])
      .pipe(withLatestFrom(this.selectedGraph$), takeUntil(this.ngUnsubscribe))
      .subscribe(([[selectable, _], selectedGraph]) => {
        let graph =
          selectedGraph === GraphTypes.None
            ? this.graphSidebarOptionsService.getLastCachedGraph()
            : selectedGraph;
        if (selectable.length > 0) {
          this.message$.next(null);
          if (!selectable.some((s) => s.id === graph.id)) {
            this.selectedGraph$.next(selectable[0]);
          } else {
            this.selectedGraph$.next(graph);
          }
        } else {
          this.selectedGraph$.next(GraphTypes.None);
          this.message$.next('No graphs available for this table.');
        }
        this.ngsZoomService.deregisterZoomControls();
      });
    this.anyGraphsAvailable$ = this.selectableGraphs$.pipe(
      map((graphs) => graphs && graphs?.length > 0),
    );
    this.selectedGraph$
      .pipe(
        distinctUntilChanged((oldGraph, newGraph) => oldGraph.id === newGraph.id),
        withLatestFrom(this.documentID$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([graph, id]) => {
        this.store.dispatch(
          ngsGraphActions.params.selectedGraph.update({
            id,
            graphId: graph.id,
            value: {
              selectedGraph: graph.id,
            },
          }),
        );
      });
    this.selectionDisplayType$
      .pipe(
        distinctUntilChanged((oldType, newType) => oldType.id === newType.id),
        withLatestFrom(this.documentID$, this.selectedGraph$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([displayType, id, graph]: [SelectionDisplayType, string, NgsGraph]) => {
        this.store.dispatch(
          ngsGraphActions.params.selectionDisplayType.update({
            id,
            graphId: graph.id,
            value: {
              selectionDisplayType: displayType.id,
            },
          }),
        );
      });
    this.shouldDisplaySelectionDisplayType$ = this.selectedGraph$.pipe(
      map((graph) => !isIgnorableParameterForGraph(graph.id, 'tableSelection')),
      distinctUntilChanged(),
    );

    this.hideControlsExceptGraphSelector$ = combineLatest([this.selectableGraphsLoading$]).pipe(
      map(([graphsLoading]) => {
        return graphsLoading;
      }),
    );

    this.graphIdForSidebar$ = this.currentGraphParams$.pipe(
      filter(
        (currentGraphParams) =>
          !!(
            currentGraphParams &&
            currentGraphParams?.id &&
            currentGraphParams?.selectedGraph &&
            currentGraphParams?.selectedTable
          ),
      ),
      map((currentGraphParams) => {
        const { id, selectedGraph, selectedTable } = currentGraphParams;
        return {
          id,
          graph: selectedGraph.value,
          table: selectedTable.value.name,
          asString: `${id}-${selectedGraph.value}-${selectedTable.value.name}`,
        } as GraphSidebarId;
      }),
      distinctUntilChanged((x, y) => x?.asString === y?.asString),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    );
    this.graphIdForSidebar$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.showGraph$.next(false);
      setTimeout(() => {
        this.showGraph$.next(true);
      }, 0);
    });
  }

  ngOnDestroy() {
    this.selectedGraph$.complete();
    this.selectionDisplayType$.complete();
    this.controls$.complete();
    this.message$.complete();
  }

  onGraphChanged(graph: NgsGraph) {
    this.ngsZoomService.deregisterZoomControls();
    this.selectedGraph$.next(graph);
    this.graphSidebarOptionsService.setLastCachedGraph(graph.id);
  }

  onSelectionDisplayTypeChanged(type: SelectionDisplayType) {
    this.selectionDisplayType$.next(type);
    this.graphSidebarOptionsService.setLastSelectionDisplayType(type);
  }

  onGraphWarning(warning: string) {
    this.graphWarning$.next(warning);
  }

  exportAsTable() {
    this.chartComponent.exportAsTable();
  }

  exportAsImage() {
    this.chartComponent.exportAsImage();
  }

  getSelectableGraphs(): Observable<NgsGraph[]> {
    return this.currentGraphParams$.pipe(
      filter(({ id, ...currentSelection }) => currentSelection?.selectedTable?.valid),
      map(({ selectedTable }) => selectedTable.value),
      withLatestFrom(this.document$, this.tablesForDocument$),
      distinctUntilChanged(([oldTable], [newTable]) => oldTable.name === newTable.name),
      switchMap(([table, doc, otherTables]) =>
        this.graphCompatibilityService.getSelectableGraphsForDocumentAndTable(
          table,
          doc,
          otherTables,
        ),
      ),
      startWith([]),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getTablesForDocument(documentId: string): Observable<Record<string, DocumentTable>> {
    return this.documentTableStateService.getTablesMap(documentId).pipe(take(1));
  }

  getChartComponent() {
    return this.chartComponent;
  }

  onSidebarToggled() {
    this.getChartComponent().onSidebarToggled();
  }

  onControlsChanged(controls: any) {
    this.graphIdForSidebar$.pipe(take(1)).subscribe((id) => {
      if (!!id) {
        this.graphSidebarOptionsService.setSavedOptions(id.asString, controls);
        this.graphSidebarOptionsService.setCachedOptions(id.graph, controls);
      }
    });
    this.getChartComponent()?.onControlsChanged(controls);
  }

  onControlsUpdated(controls: GraphSidebarControl[]) {
    this.controls$.next(controls);
  }

  onPngExportEnabledUpdated(enabled: boolean) {
    this.pngExportEnabled$.next(enabled);
  }

  onTableExportEnabledUpdated(enabled: boolean) {
    this.tableExportEnabled$.next(enabled);
  }
  protected readonly warningIcon = faExclamationTriangle;
}

export interface GraphSidebarId {
  graph: GraphId;
  table: string;
  id: string;
  asString: `${string}-${string}-${string}`;
}
